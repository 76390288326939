export default {
  adminDashboard: {
    chats: {
      chart: {
        answeredWithin4Hours: {
          caption:
            'per month out of total {{total}} conversations the {{year}} year',
          line: {
            answeredWithin4Hours: 'Answered within 4 hours',
          },
          title: 'Chats answered within first 4 hours',
        },
        closedOpened: {
          caption: 'per month out of total {{total}} conversations',
          key: {
            closed: 'Closed',
            open: 'Open',
          },
          title: 'Closed vs opened',
        },
        conversations: {
          caption: 'this year {{year}}',
          title: 'Conversations',
        },
        reasons: {
          caption: 'per month out of total {{total}} conversations',
          title: 'Reasons for entry',
        },
        responseTime: {
          caption:
            'per month out of total {{total}} conversations the {{year}} year',
          line: {
            firstReplyTime: 'First reply time',
            openedTime: 'Opened time',
          },
          title: 'Response time',
          yAxis: 'hours',
        },
      },
      monthly: 'Monthly',
      title: 'Chats',
    },
    tabs: {
      chats: 'Chats Dashboard',
      users: 'Users Dashboard',
    },
    users: {
      actions: {
        download: 'Download users',
      },
      chart: {
        activePatients: {
          caption: '{{activePatients}} out of {{patients}} this month',
          title: 'Active patients',
        },
        newUsersByMonth: {
          caption: 'by month in {{year}}',
          title: 'New patients ',
        },
        pie: {
          clinicians: 'Clinicians',
          unverified: 'Unverified',
          verifiedPatients: 'Verified patients',
        },
        totalUsers: {
          caption: 'out of total {{users}} users',
          title: 'Users',
        },
      },
      title: 'Users',
    },
  },
  auth: {
    logout: 'Logout',
    noPermissions: `You don't have permissions to access this page.`,
  },
  chat: {
    actions: {
      approveAndSend: 'Approve and send',
      cancel: 'Cancel',
      close: 'Close chat',
      edit: 'Edit',
      returnToQueue: 'Return to queue',
      revert: 'Discard changes',
      save: 'Save',
      start: 'Start chatting',
    },
    assign: {
      button: 'Assign to',
      confirmModal: {
        button: 'Assign chat',
        cancel: 'Cancel',
        confirm: 'Assign chat',
        header: 'Assign chat?',
        text: 'If you assign the chat, the internal chat will be closed. You can view past internal chats up to one week old in the patient information panel.',
      },
      modal: {
        button: 'Assign chat',
        header: 'Assign to',
        inputPlaceholder: 'Search all users',
      },
    },
    close: {
      cancel: 'Cancel',
      confirm: 'Close Chat',
      confirmAndSaveReport: 'Close chat & save report',
      conversation: {
        header: 'Close chat?',
        text: 'Closing this chat will also close internal chats. This action cannot be undone. You can view past chats up to one week old in the patient information panel.',
      },
      includeImages: 'Include images sent by the patient',
      internal: {
        header: 'Close internal chat?',
        text: 'Closing the internal chat will not close the chat with the patient. You can view past internal chats up to one week old in the patient information panel.',
      },
      patientNotes: 'Patient record notes',
      saveReport: 'Save report for this chat',
      triageNote: 'Triage answers will be included automatically',
    },
    events: {
      conversationAssignedTo: 'Chat has been assigned to {{user}}',
      conversationClosedBy: 'Chat has been closed by {{user}}',
      conversationMovedToQueue: 'Chat has been moved to {{queue}}',
      conversationReasonForEntryChanged:
        'Reason for entry changed from {{old}} to {{new}} by {{user}}',
      conversationUnassignedBy:
        'Chat has been returned to the queue by {{user}}',
      conversationUnassignedBySystem:
        'Chat has been returned to the queue automatically by the system',
    },
    hiddenImage: 'Images are not available in past chats',
    inbox: 'Inbox',
    inputPlaceholder: 'Write your message here',
    noActiveConversations: 'Select a patient to start chatting',
    notifications: {
      conversationCreated: 'New conversation from {{user}}',
      internalConversationCreated: 'New internal chat from {{user}}',
      messageAdded: 'New message from {{user}}',
    },
    ongoingConversations: 'Ongoing chats',
    queue: 'Queue',
    reasons: {
      Booking: 'Booking',
      'Hand therapy': 'Hand Therapy',
      Insurance: 'Insurance',
      Medication: 'Medication',
      Other: 'Other',
      Questionnaires: 'Questionnaires',
      Surgery: 'Surgery',
      Urgent: 'Urgent',
    },
    returnToQueue: {
      cancel: 'Cancel',
      confirm: 'Return to queue',
      header: 'Return chat to queue?',
      text: 'If you return the chat to the queue, the internal chat will be closed and the other party won’t be able to see the internal chat anymore. You can view past internal chats up to one week old in the patient information panel.',
    },
    suggestion: {
      feedback: {
        defects: 'Defects',
        editedMessage: 'Edited message:',
        header: 'Review edited response',
        originalMessage: 'Original message:',
        send: 'Send message',
      },
      messageLabel: 'Please review this generated response',
    },
    triage: {
      accordionTitle: 'Patient triage questions',
    },
    userTyping: '{{name}} is writing',
  },
  generic: {
    noEmail: 'NO EMAIL AVAILABLE',
  },
  handTherapy: {
    actions: {
      add: 'add',
      cancel: 'cancel',
      confirm: 'assign program',
      remove: 'remove',
    },
    changeProgram: 'Change selected program',
    exercises: {
      button: 'Add exercise',
      frequency: 'Frequency',
      frequencyOptions: {
        day: 'day',
        hour: 'hour',
      },
      instructions: 'Instructions',
      reps: 'Reps / Duration',
      rest: 'Rest',
      sets: 'Sets',
      title: 'Exercises',
      videoPreview: 'Video preview',
    },
    notFound: {
      backTo: 'Search patients',
      title:
        "Sorry, we can't find the program {{programId}} anymore. Search for a patient to continue.",
    },
    sidebar: {
      allAreasLabel: 'All areas of concern',
      area: {
        dexterity: 'Exercise room: dexterity',
        elbow: 'Elbow',
        finger: 'Finger',
        general: 'ADL exercises',
        mobility: 'Exercise room: mobility',
        nerve: 'Nerve',
        shoulder: 'Shoulder',
        strength: 'Exercise room: strength',
        thumb: 'Thumb',
        wrist: 'Wrist',
      },
      areaOfConcernPlaceholder: 'Area of concern',
      searchExercisesPlaceholder: 'Search all exercises',
      title: {
        exercises: 'Exercises',
        splints: 'Splint',
      },
    },
    splints: {
      button: 'Add splint',
      frequencyOptions: {
        day: 'day',
        'day-night': 'day and night',
        night: 'night',
      },
      instructions: 'Instructions',
      title: 'Splint',
      wearDuring: 'Wear During',
    },
  },
  internalChat: {
    action: 'Start internal chat',
    placeholder: 'Write a message',
    searchbarPlaceholder: 'Search users',
    title: 'Internal chat:',
  },
  login: {
    confirm: 'Log in',
    description: 'Log in with eCumulus to continue',
  },
  navbar: {
    adminPanel: 'admin',
    chat: 'Chat',
    logout: 'Logout',
    patients: 'Patients',
  },
  patientScreen: {
    navigation: {
      exercises: {
        description:
          'Create an exercise plan for your patients. Assign splint and exercise instructions',
        title: 'Exercises',
      },
      openButton: 'Open',
      pdfLookup: {
        description:
          'Search for diagnosis information. Share or print care path information.',
        title: 'PDF Lookup',
      },
      prospectus: {
        description: 'Create a treatment plan for Velthuis patients',
        title: 'Brochure',
      },
    },
  },
  patientSearch: {
    actions: {
      clear: 'Clear',
    },
    instructions: 'Press Enter to search patients',
    noResults: 'No patients found',
    searchbarPlaceholder: 'Search by patient name or ID',
    title: 'Hello, {{name}}',
  },
  patientSidebar: {
    carePath: {
      closedCarePaths: 'Closed treatments',
      currentCarePaths: 'Current treatments',
      joinCall: 'Join Call',
      loadingAppointments: 'Loading...',
      nMore: '{{num}} more',
      noActiveCarePaths: 'No active treatments',
      noClosedCarePaths: 'no closed treatments',
      noContent: 'There are no appointments to display.',
      noMoreAppointments: 'No more appointments',
      numActiveCarePathsPlural: '{{num}} active treatments',
      numActiveCarePathsSingular: '1 active treatment',
      numClosedCarePathsPlural: '{{num}} closed treatments',
      numClosedCarePathsSingular: '1 closed treatment',
      past: 'Past',
      planned: 'Planned',
      today: 'Today',
    },
    chats: {
      backToList: 'Back to chat list',
      internal: {
        title: 'Internal chat',
      },
      noContent: 'There are no chats to display.',
      noEvents: 'There are no events to display.',
      ongoing: 'Ongoing',
      past: 'Past',
    },
    expandProfile: 'Expand patient profile',
    pdfs: {
      created: 'Treatment: {{- date}}',
      error: {
        description: 'Please try again later',
        title: 'Something went wrong',
      },
      noCarePlanSelected: {
        description: 'Please select a care plan from the list',
        title: 'There are no items to display right now',
      },
      noCarePlans: 'No care plans found',
      onGoingCarePlans: 'Ongoing care plans',
      pastCarePlans: 'Past care plans',
      sendPDF: {
        cancel: 'Cancel',
        confirm: 'Send',
        error: 'Something went wrong. Please try again later.',
        header: 'Send client instruction to patient',
        success: 'The PDF has been sent successfully.',
        text: "Are you sure you want to send the PDF to the patient's email address {{email}}?",
      },
    },
    questionnaires: {
      completed: 'Completed',
      expired: 'Expired',
      noContent: 'There are no questionnaires to display.',
      patient: 'Patient Questionnaires',
      therapist: 'Therapist Questionnaires',
      todo: 'Todo',
    },
    tabs: {
      carePath: 'Appointments',
      chats: 'Chat',
      questionnaires: 'Questionnaires',
    },
  },
  preCall: {
    description:
      'Scan the QR code with a mobile device to join the video call. Alternatively, type in the link below in the browser of your mobile device and enter the code.',
  },
  programSelection: {
    actions: {
      cancel: 'Cancel',
      confirm: 'Select program',
    },
    exercise: 'Exercise',
    splint: 'Splint',
    titles: {
      change: 'Change program',
      select: 'Select program',
    },
  },
  prospectus: {
    actions: {
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    addTreatment: 'Add treatment',
    allCategories: 'All',
    confirm: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      header: 'Confirm treatment',
      text: 'Are you sure you want to confirm the treatment?',
    },
    failedToEdit: 'Failed to edit brochure',
    filterPlaceholder: 'Filter by category',
    noProspectuses:
      'This patient has no brochure yet. Add a treatment to get started.',
    removeProspectus: 'Remove treatment',
    searchPlaceholder: 'Search all treatments',
    sidebarTitle: 'Brochure',
    title: 'Brochure',
  },
  userManagement: {
    actions: {
      cancel: 'Cancel',
      delete: 'Delete',
      save: 'Save',
    },
    featureFlags: {
      actions: {
        add: 'New feature flag',
      },
      columns: {
        actions: 'Actions',
        description: 'Description',
        enabled: 'Enabled',
        key: 'Key',
        title: 'Title',
        value: 'Value',
      },
      createModal: {
        error:
          'There was problem creating new feature flag, check if key is unique',
        title: 'Create new feature flag',
      },
      deleteModal: {
        caption: 'Are you sure you want to delete feature flag {{title}}?',
        error: 'There was problem deleting feature flag',
        title: 'Delete feature flag',
      },
      editModal: {
        error: 'There was problem updating feature flag',
        title: 'Edit feature flag',
      },
      expandFeatureFlag: {
        actions: {
          clearSelection: 'Reset',
          deleteSelected: 'Delete',
          downloadCsv: 'Download values',
          selectAll: 'Select all',
          uploadCsv: 'Upload CSV',
        },
        addNewValuePlaceholder: 'Add new value or paste multiple rows',
        breadcrumb: 'Back to all features',
        error: {
          errorUpdatingFeatureFlag:
            'Error updating feature flag. Please try again.',
          noFeatureFlagFound:
            'Feature flag with key {{key}} not found. Please go back.',
          noItemsFound: 'No items found with {{query}}',
        },
        searchbarPlaceholder: 'Search...',
        uploadFileModal: {
          actions: {
            save: 'Save',
          },
          error: {
            fileNotCSV: 'Invalid file type. Please provide a CSV file',
            noFileProvided: 'No file provided',
          },
          title: 'Upload CSV file',
        },
        values: 'Values',
      },
      title: 'Feature flags',
    },
    permissions: {
      actions: {
        add: 'New permission',
      },
      columns: {
        actions: 'Actions',
        description: 'Description',
        name: 'Name',
      },
      createModal: {
        caption:
          'Permissions should also be added to the codebase to be used in the application',
        error:
          'There was problem creating new permission, check if name is unique',
        title: 'Create new permission',
      },
      deleteModal: {
        caption: 'Are you sure you want to delete permission {{name}}?',
        error:
          'There was problem deleting permission, make sure it is not in use',
        title: 'Delete permission',
      },
      editModal: {
        error: 'There was problem updating permission, check if name is unique',
        title: 'Edit permission',
      },
      title: 'Permissions',
    },
    queues: {
      actions: {
        add: 'New queue',
      },
      columns: {
        actions: 'Actions',
        description: 'Description',
        title: 'Title',
        userGroups: 'User groups',
      },
      createModal: {
        error:
          'There was problem creating new queue, check if name is unique and all the fields are present',
        title: 'Create new queue',
      },
      deleteModal: {
        caption: 'Are you sure you want to delete queue {{title}}?',
        error:
          'There was problem deleting the queue, check if it does not have user groups attached',
        title: 'Delete queue',
      },
      editModal: {
        error:
          'There was problem updating queue, check if name is unique and all the fields are present',
        title: 'Edit queue',
      },
      title: 'Queues',
    },
    reasons: {
      actions: {
        add: 'New reason',
        editOrder: 'Edit order',
        saveOrder: 'Save order',
      },
      columns: {
        actions: 'Actions',
        contentfulId: 'Contentful ID',
        organizations: 'Organizations',
        priority: 'Priority',
        queue: 'Queue',
        title: 'Title',
        urgent: 'Urgent',
      },
      createModal: {
        error:
          'There was problem creating new reason, check if title is unique and all the fields are present',
        title: 'Create new reason',
      },
      deleteModal: {
        caption: 'Are you sure you want to delete reason {{title}}?',
        error:
          'There was problem deleting reason, make sure it is not in use in conversations',
        title: 'Delete reason',
      },
      editModal: {
        error:
          'There was problem updating reason, check if title is unique and all the fields are present',
        title: 'Edit reason',
      },
      title: 'Reasons',
    },
    roles: {
      actions: {
        add: 'New role',
      },
      columns: {
        actions: 'Actions',
        azureGroups: 'Azure Groups',
        description: 'Description',
        name: 'Name',
        permissions: 'Permissions',
        value: 'Value',
      },
      createModal: {
        error:
          'There was problem creating new role, check if name is unique and permissions are present',
        title: 'Create new role',
      },
      deleteModal: {
        caption: 'Are you sure you want to delete role {{name}}?',
        error: 'There was problem deleting role, make sure it is not in use',
        title: 'Delete role',
      },
      editModal: {
        error: 'There was problem updating role, check if name is unique',
        title: 'Edit role',
      },
      title: 'Roles',
    },
    tabs: {
      featureFlags: 'Feature flags',
      reasons: 'Reasons and Queues',
      roles: 'Roles and Permissions',
      users: 'Users',
    },
    users: {
      actions: {
        add: 'New user',
        download: 'Download users',
      },
      columns: {
        actions: 'Actions',
        email: 'Email',
        lastSignIn: 'Last sign in',
        name: 'Name',
        patientId: 'Patient ID',
        roles: 'Roles',
        verified: 'Verified',
      },
      createModal: {
        error:
          'There was problem creating new user, check if email is unique and name is present',
        title: 'Create new user',
      },
      deleteModal: {
        caption: 'Are you sure you want to delete user {{email}}?',
        error: 'There was problem deleting user',
        title: 'Delete user',
      },
      display: 'Display: {{amount}} of {{total}}',
      editModal: {
        error: 'There was problem updating user, check if email is unique',
        title: 'Edit user',
      },
      title: 'Users',
    },
  },
}
